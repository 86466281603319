import React from 'react';
import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

const Hero = () => {
  const theme = useTheme();
  return (
    <Box
   
    >
      <Box position={'relative'} zIndex={2}>
        <Box marginY={6}>
          <iframe
            width="100%"
            height="100%"
            frameBorder="0"
            title="map"
            marginHeight={0}
            marginWidth={0}
            scrolling="no"
            src="https://maps.google.com/maps?width=100%&height=100%&hl=en&q=NPSYpr&ie=UTF8&t=&z=14&iwloc=B&output=embed"
            style={{
              minHeight: 300,
              borderRadius: 6,
              filter:
                theme.palette.mode === 'dark'
                  ? 'grayscale(0.5) opacity(0.7)'
                  : 'none',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
