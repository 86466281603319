import React, { useState } from 'react';
// import 'react-calendar/dist/Calendar.css'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
// import Calendar from 'react-calendar';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import { CenterFocusStrong } from '@mui/icons-material';
import Slider from 'react-slick';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Button } from '@mui/material';


const startYear = new Date(2014, 7, 1);
const currentYear = new Date().getFullYear();
const numberOfYears = currentYear - startYear.getFullYear()+1;

const mock = [
  {
    title: numberOfYears,
    subtitle:
      'YEARS OF EXCELLENCE',
    suffix: numberOfYears === 1 ? '+' : '+',
  },
  {
    title: 1959,
    subtitle:
      'STUDENTS ENROLLED',
    suffix: '+',
  },
  {
    title: 100,
    subtitle: 'PASS PERCENTAGE',
    suffix: '%',
  },
];

const GetStarted = () => {
  const theme = useTheme();
  const [value, onChange] = useState(new Date());
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const sliderOpts = {
    dots: true,
    
    infinite: true,
    speed: 6000,
    // sleep: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 8800,
  };
 
  const [date, setDate] = useState(new Date())
  
  const [viewPortEntered, setViewPortEntered] = useState(false);
  const setViewPortVisibility = (isVisible) => {
    if (viewPortEntered) {
      return;
    }

    setViewPortEntered(isVisible);
  };

  return (
    <Box>
       
      <Grid item xs={24} md={12} >
        <Box marginBottom={1}>
          
          <Typography sx={{ fontWeight: 700 }} variant={'h4'} gutterBottom color={'white'}>
            NPS YPR Achievements
          </Typography> 
          <Typography variant={'h6'}  color={'white'}>
            The Numbers Say It All
            </Typography>
            <Typography variant={'h6'} color={'white'}>
            We stand out ....
          </Typography>
        </Box>
        <Grid container spacing={1}>
          {mock.map((item, i) => (
            <Grid key={i} item xs={12} md={4}>
              <Typography variant="h4" color={'white'} gutterBottom>
                <VisibilitySensor
                  onChange={(isVisible) => setViewPortVisibility(isVisible)}
                  delayedCall
                >
                  <CountUp
                    duration={2}
                    justifyContent={CenterFocusStrong}
                    end={viewPortEntered ? item.title : 0}
                    start={0}
                    suffix={item.suffix}
                  />
                </VisibilitySensor>
              </Typography>
              <Typography color={'white'} >
                {item.subtitle}
              </Typography>
              
            </Grid>
          ))}
        </Grid>
      </Grid>

    
  </Box>
  );
};

export default GetStarted;