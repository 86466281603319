import React from 'react';
import Slider from 'react-slick';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

const mock = [
  {
    feedback:
      'The best part was, it taught children about unity and what wonders a team work can do.It feels great to be a part of NPS family.',
    name: 'Harish',
    },
  {
    feedback:
      'My kids had a wonderful time learning in your institution. They learnt a lot and had fun too and they are always eager to go school. We have been lucky to have a school like this in our neighbourhood. Thank you for all your help and support',
    name: 'Shantha Pathak',  
    },
  {
    feedback:

    "We would like to congratulate the graduating class X students , the teaching staff, the support staff and the school for the commendable result of the first CBSE X batch. Special appreciation to the Principal for leading the team so well to reach the milestone.We are happy for the school and we look forward to many more accolades in the coming years.",
    name: 'Ravikumar Shankaran',
  },
  {
    feedback:

    "The best part was, it taught children about unity and what wonders a team work can do.It feels great to be a part of NPS family.",
    name: 'Amit Sethia',
  },
  {
    feedback:

    "We feel that we have made the right decision by joining our son in this great disciplined organization. All the staff members are highly qualified and are deeply devoted to their trade. It is surprising that the Principal knows each student by their name. We are sure that she channels her feedback to the relevant members of her staff enabling to fine-tune their delivery. We are confident that our child is in safe hands and will groom in to a beautiful butterfly.",
    name: 'Vasudev ',
  },
  {
    feedback:

    "The way the entire event was meticulously planned, organized and executed made me feel very happy and proud to be a part of the great NPS family. Hats off to all the teachers for all the effort they have taken amidst their academic commitments.",
    name: 'Mathura Harish',
  },
];

const Reviews = () => {
  const sliderOpts = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 6000,
  };

  return (
    <Box maxWidth={800} width={1} margin={'0 auto'}>
    {/* // style = {{backgroundImage: 'https://www.npsypr.com/images/banner/2k22/banner_i1_2k22.jpg'}}> */}
      <Typography
        variant={'h4'}
        align={'center'}
        sx={{ fontWeight: 700, marginBottom: 4, color: 'black' }}
      >
        Testimonial
      </Typography>
      
      <Slider {...sliderOpts}>
        {mock.map((item, i) => (
          <Box key={i} paddingX={4}>
            <Typography
              align={'center'}
              variant={'h6'}
              sx={{ color: 'black', fontStyle: 'italic' }}
            >
              {item.feedback}
            </Typography>
            <Typography
              align={'center'}
              variant={'h6'}
              sx={{ color: 'black', fontStyle: 'italic' }}
            >
              {item.name}
            </Typography>
            <Button 
           align = 'center'
           href = '/testimonial'>
        <Typography color = 'black'>
          Know More
        </Typography>
      </Button>
          </Box>
        ))}
      </Slider>
      
    </Box>
  );
};

export default Reviews;
